import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import FormsApi from "./api/forms_api";
import { PDFDocument } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import SignatureModal from "./SignatureModal";
import PDFViewer from "./PDFViewer";

const Container = styled.div`
    text-align: right;
    direction: rtl;
  font-family: "Noto Sans Hebrew", sans-serif;
`;

const FormContainer = styled.div`
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const LogoImage = styled.img`
    margin-top: 10px;
  max-width: 250px;
  max-height: 80px;
`;

const Button = styled.button`
  background-color: #4caf50; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: block; /* Make it block to fill the container */
  width: 100%; /* Align with screen width */
  font-size: 16px;
  margin: 10px 0 0; /* Add margin top */
  cursor: pointer;
`;

const ErrorMessage = styled.p`
  color: red; /* Error message in red */
  margin-top: 5px; /* A little space from the checkbox */
`;

const Mobile = {
    Android: 'Android',
    iOS: 'iOS',
    Other: 'Other'
};

function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
        return Mobile.Android;
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return Mobile.iOS;
    }

    return Mobile.Other;
}


const FormPage = ({ formSessionId = undefined }) => {
    const [formDetails, setFormDetails] = useState(null);
    const [isApproved, setIsApproved] = useState(false);
    const [loading, setLoading] = useState(false); // 1. Added loading state
    const [showError, setShowError] = useState(false); // State to manage error message visibility
    const [loadingText, setLoadingText] = useState("טופס בטעינה...");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [signedPdfBytes, setSignedPdfBytes] = useState(null);

    useEffect(() => {
        if(formSessionId) {
            setLoading(true);
            loadFormDetails(formSessionId).then(r => {});
        }
    }, [formSessionId]);

    useEffect(() => {
        try {
            if(signedPdfBytes) {
                handleSendSignedPdf().then(r => {});
            }
        } catch (error) {
            console.error('Error handleSendSignedPdf on useEffect:', error);
        }
    }, [signedPdfBytes]);

    // Mock function to simulate loading form details
    const loadFormDetails = async () => {
        setTimeout(() => {
            FormsApi.getFormInfo(formSessionId)
                .then((response) => {
                    if(response && response.data && response.data.approved == false) {
                        setFormDetails(response.data);
                        setLoading(false);
                    } else {
                        setFormDetails(null);
                        setLoading(false);
                    }
                }).catch((error) => {
                setFormDetails(null);
                setLoading(false);
            });
        }, 1000);
    };

    const handleOpenAppOrStore = () => {
        const isMobileAndroid = getMobileOperatingSystem() === Mobile.Android;

        if (isMobileAndroid) {
            window.location.href = 'payro://open';
        } else {
            window.location.href = "payro://";
        }
    };

    const handleApprove = () => {
        if (!isApproved) {
            setShowError(true); // Show error if not approved
            setTimeout(() => setShowError(false), 5000); // Optional: Hide error after some time
            return;
        }

        setLoadingText("שולח טופס...");
        setLoading(true);

        FormsApi.approveForm(formSessionId)
            .then((response) => {
                setTimeout(() => {
                    setLoadingText("טופס נשלח בהצלחה");

                    setTimeout(() => {
                        handleOpenAppOrStore()
                    }, 2500);

                }, 1500);

            }).catch((error) => {
            setFormDetails(null); // Clear form details on error
            setLoading(false); // Stop loading on error
        });
    };

    const handleSignatureSave = async (dataUrl) => {
        if (!formDetails || !formDetails.formContent) return;

        try {

            // Load the PDF from the Base64 string
            const pdfBytes = Uint8Array.from(atob(formDetails.formContent), c => c.charCodeAt(0));
            const pdfDoc = await PDFDocument.load(pdfBytes);

            // Register fontkit for custom font usage
            pdfDoc.registerFontkit(fontkit);

            const pages = pdfDoc.getPages();
            const firstPage = pages[0];

            // Load the signature image
            const pngImageBytes = await fetch(dataUrl).then(res => res.arrayBuffer());
            const pngImage = await pdfDoc.embedPng(pngImageBytes);

            const pngDims = pngImage.scale(0.5);
            firstPage.drawImage(pngImage, {
                x: 100,
                y: 230,
                width: pngDims.width * 0.6,
                height: pngDims.height * 0.6,
            });

            // Embed the custom font for Hebrew support
            const fontBytes = await fetch('/fonts/NotoSansHebrew-Regular.ttf').then(res => res.arrayBuffer());
            if(!fontBytes) {
                console.error('Font file not found');
                return;
            }
            // make sure formDetails.userFullName and formDetails.userGovId is exist
            if(!formDetails.userFullName || !formDetails.userGovId) {
                console.error('userFullName or userGovId is missing');
                return;
            }

            const customFont = await pdfDoc.embedFont(fontBytes);

            // Draw full name (Hebrew) at a specific position
            firstPage.drawText(formDetails.userFullName, {
                x: 130,
                y: 200,
                size: 12,
                font: customFont
            });

            // Draw current date (dd/MM/yyyy) at another position
            const currentDate = new Date().toLocaleDateString('en-GB'); // Format: dd/MM/yyyy
            firstPage.drawText(currentDate, {
                x: 70,
                y: 750,
                size: 12,
                font: customFont
            });

            // Draw government ID (Hebrew) at a different position
            firstPage.drawText(formDetails.userGovId, {
                x: 110,
                y: 165,
                size: 12,
                font: customFont
            });

            // Save the signed PDF
            const signedBytes = await pdfDoc.save();
            setSignedPdfBytes(signedBytes);

        } catch (error) {
            console.error('Error handling signature save:', error);
        }
    };

    const handleSendSignedPdf = async () => {
        if (!signedPdfBytes) return;

        try {
            // Convert signedPdfBytes to Base64
            const base64Pdf = btoa(String.fromCharCode(...new Uint8Array(signedPdfBytes)));

            const response = await FormsApi.approveForm(formSessionId, base64Pdf);

            setLoadingText("שולח טופס...");
            setLoading(true);

            setTimeout(() => {
                setLoadingText("טופס נשלח בהצלחה");

                setTimeout(() => {
                    handleOpenAppOrStore()
                }, 2500);

            }, 1500);

        } catch (error) {
            console.error('Error sending signed PDF:', error);
            setFormDetails(null); // Clear form details on error
            setLoading(false); // Stop loading on error
        }
    };


    const renderContent = () => {
        if (loading) return (
            <>
                <Container>
                    <h1 style={{ textAlign: 'center' }}>{loadingText}</h1>
                </Container>
            </>)
            ;
        if (!formDetails) return (
            <>
                <Container>
                    <h1 style={{textAlign: 'center'}}>טופס לא קיים</h1>
                </Container>
            </>)
            ;

        return (
            <>
                <LogoImage src={formDetails.companyLogo} alt="Employer Logo" />
                <h1>{formDetails.formTitle}</h1>

                {formDetails.formContentType === 'pdf' ? (
                    <>
                        {!signedPdfBytes ? (
                            <PDFViewer pdfDriveLinkUrl={formDetails.externalContentLink} />
                        ) : null}
                    </>
                ) : (
                    <p>{formDetails.formContent}</p>
                )}
            </>
        );
    };

    return (

        <Container>
            <FormContainer>
                {renderContent()}
                {formDetails && !loading && (
                    <>
                        {formDetails.formContentType === 'pdf' ? (
                            <>
                                {!signedPdfBytes ? (
                                    <Button onClick={() => setIsModalOpen(true)} disabled={loading}>
                                        חתימה על הטופס
                                    </Button>
                                ) : (
                                    <Button onClick={handleSendSignedPdf} disabled={!signedPdfBytes}>
                                        שלח טופס
                                    </Button>
                                )}

                                <SignatureModal
                                    isOpen={isModalOpen}
                                    onClose={() => setIsModalOpen(false)}
                                    onSave={handleSignatureSave}
                                />
                            </>
                        ) : (
                            <>
                                <label>
                                    <input
                                        type="checkbox"
                                        onChange={(e) => setIsApproved(e.target.checked)}
                                        checked={isApproved}
                                    />
                                    אני מאשר/ת את הטופס הנ״ל
                                </label>
                                {showError && <ErrorMessage>אנא אשר את הטופס</ErrorMessage>}
                                <Button onClick={handleApprove} disabled={loading}>
                                    שלח טופס
                                </Button>
                            </>
                        )}
                    </>
                )}
            </FormContainer>
        </Container>
    );
};

export default FormPage;

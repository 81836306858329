// SignatureModal.js
import React, {useEffect, useRef, useState} from 'react';
import SignatureCanvas from 'react-signature-canvas';
import './SignatureModal.css'; // Add your styles here

const SignatureModal = ({ isOpen, onClose, onSave }) => {
    const sigCanvas = useRef();

    const saveSignature = () => {
        if (sigCanvas.current) {
            const dataUrl = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
            onSave(dataUrl);
            onClose();
        }
    };

    const clearSignature = () => {
        if (sigCanvas.current) {
            sigCanvas.current.clear();
        }
    };

    if (!isOpen) {
        return null;
    }

    return (
        <div className="modal">
            <div className="modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <div className="center-text">חתום כאן</div>
                <SignatureCanvas
                    ref={sigCanvas}
                    canvasProps={{width: 300, height: 200, className: 'sigCanvas', style: {border: '1px solid black'}}}
                />
                <button onClick={saveSignature}>שליחת הטופס</button>
                <button onClick={clearSignature}>נקה</button>
            </div>
        </div>
    );
};

export default SignatureModal;
